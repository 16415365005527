@import url('https://fonts.googleapis.com/css?family=Open+Sans&subset=latin-ext');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html, body {
    margin: 0px;
    padding: 0px;
    min-height: 100vh;
}

div#root {
    min-height: 100vh;
}